.slider {
  position: relative;
  height: 95vh;
  display: flex;
}

/* .slider .navigation {
  height: 100%;
  width: 30vw;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider .navigation .count {
  color: #444444;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 6rem;
  margin: 25px 0 25px 0;
} */

/* .image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
} */

/* .down-arrow {
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.up-arrow {
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
} */

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity ease-in-out 0.9s;
  /* transform: scale(0.8); */
}

.slider .content_section {
  bottom: 0;
  /* text-align: center; */
  margin: 30vh calc((100vw - 1100px) / 2);
  color: white;
}

.slider .content_section .heading {
  font-family: "Belleza", sans-serif;
  font-weight: 900;
  text-align: left;
  font-size: 3.2rem;
}

/* .slider .content_section .para {
  margin-top: 2%;
  font-family: "Poppins", sans-serif !important;
  font-size: 1.3rem;
} */

.slide.active {
  opacity: 1;
  background-blend-mode: overlay;
  /* transform: scale(1); */
}

.slider-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .slider {
    height: 45vh;
  }

  .slider .content_section {
    margin: 25% 5% 0 5%;
  }

  .slider .content_section .heading {
    font-size: 2rem;
  }
}
