.image-section {
  display: flex;
  flex-direction: row;
}

.image-sub-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 36px 0 36px;
}

.image-section .second_image {
  flex: 1;
  position: relative;
  margin-top: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/home-temple.jpeg");
}

@media screen and (max-width: 768px) {
  .image-section .second_image {
    display: none;
  }

  .image-sub-section {
    padding: 0 16px 0 16px;
  }
}

.image-section .second_image .text {
  position: absolute;
  top: 30px;
  right: -30px;
  height: 60%;
  margin-left: 30%;
  /* background-color: #161616; */
  border-color: black;
  border-style: solid;
  border-width: 10px;
  color: white;
  font-size: 2rem;
  font-family: "Belleza", sans-serif;
  font-weight: 800;
  padding: 30px;
}
